import * as React from "react"

import appImage from "../assets/mobileApp.webp"
import appQrCode from "../assets/aplikacja_qr_code.png"
import styled from "styled-components"

interface MobileAppProps {}

const AppImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  > :not(:first-child) {
    margin-left: 20px;
  }
  .appImage {
    width: 300px;
    border-radius: 30px;
    box-shadow: 0 0 18px -10px #000;
  }
  .qrCode {
    width: 200px;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex-direction: column;
    .qrCode {
      display: none;
    }
    > :not(:first-child) {
      margin-left: 0px;
    }
  }
`

const Header = styled.h3`
  font-size: 24px;
  margin: 20px 0;
  text-align: center;
`
const CheckButton = styled.a`
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
  padding: 20px;
  border-radius: 20px;
  margin: 20px 0;
  font-weight: bold;
  text-align: center;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  :not(:first-child) {
    margin-top: 20px;
  }
`

export const MobileApp: React.FC<MobileAppProps> = () => {
  return (
    <>
      <Header>Aplikacja mobilna!</Header>
      <AppImage>
        <img
          className="appImage"
          src={appImage}
          alt="Auto szkoła SMART, aplikacja mobilna"
        />
        <Wrapper>
          <img
            className="qrCode"
            src={appQrCode}
            alt="Auto szkoła SMART, aplikacja mobilna, qr code"
          />
          <CheckButton
            href="https://biz.prawko.pl/szkola-jazdy/autoszkolasmart"
            target="_blank"
          >
            Sprawdź aplikację!
          </CheckButton>
        </Wrapper>
      </AppImage>
    </>
  )
}

export default MobileApp

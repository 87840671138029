import React from "react"
import styled from "styled-components"
import { PageTitle } from "../../components/PageTitle"
import { Layout } from "../../layout/Layout"
import { TextBox } from "../../components/TextBox"
import HelmetData from "../../components/HelmetData"
import MobileApp from "../../components/MobileApp"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: center;
  padding: 20px;
`

export const Oferta = () => {
  return (
    <Layout>
      <HelmetData page="Oferta" canon="/oferta" />
      <PageTitle title="OFERTA kat B." />
      <Content>
        <TextBox bottomLine>
          Szkolenie rozpoczyna się w tym samym dniu w którym się zapiszesz.
        </TextBox>
        <TextBox bottomLine>
          Cykl wykładów online na prawo jazdy to 10 spotkań, w cyklu
          2-tygodniowym. Transmisja internetowa Wykładów LIVE na prawo jazdy
          rozpoczyna się w poniedziałek, wtorek, środę, czwartek, piątek o
          godzinie 18:00.
        </TextBox>
        <TextBox bottomLine>
          Ponad 2 000 grafik, zdjęć i filmów <br />
          Baza wiedzy 24H – smartfon, tablet, komputer
        </TextBox>
        <TextBox bottomLine>
          W trakcie kursu realizowane jest szkolenie w zakresie udzielania
          pierwszej pomocy przedmedycznej. Zajęcia te odbywają się stacjonarnie
          na sali wykładowej w Domu Kultury Relax przy ul. Rydzowej 5.
        </TextBox>
        <TextBox>
          Jazdy odbywają się w przyjemnej, bezstresowej atmosferze samochodem
          nowy Hyundai i20 (takim samym jak na egzaminie). Posiadamy plac
          manewrowy w pobliżu WORD. Podczas szkolenia każdemu z kursantów dajemy
          możliwość zapoznania i poćwiczenia manewrów na placu egzaminacyjnym
          łódzkiego WORD. Jeździmy również trasami egzaminacyjnymi.
        </TextBox>
      </Content>
      <MobileApp />
      <PageTitle title="Zapraszamy" marginBottom={30} />
    </Layout>
  )
}

export default Oferta
